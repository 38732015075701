<template>
    <ClientOnly>
        <component
            :is="components[implementationType]"
            v-if="implementationType && components[implementationType]"
            :url="url"
            :code="code"
            :height="height"
            :description="description"
        />
    </ClientOnly>
</template>
<script setup lang="ts">

import type {BaseEmbedProps, ComponentMap, EmbedType} from '~/components/BaseEmbed/BaseEmbed.types';

const props = defineProps<BaseEmbedProps>();

const components = {
    'buzzsprout': resolveComponent('BaseEmbedBuzzsprout'),
    'issuu': resolveComponent('BaseEmbedIssuu'),
    'flippingbook': resolveComponent('BaseEmbedFlippingbook'),
    'vimeo': resolveComponent('BaseEmbedVimeo'),
    'youtube': resolveComponent('BaseEmbedYoutube'),
    'iframe': resolveComponent('BaseEmbedIframe'),
    'tableau': resolveComponent('BaseEmbedTableau'),
    'typeform': resolveComponent('BaseEmbedTypeform'),
} as ComponentMap;

const implementationType = computed<EmbedType>(() => {
    // Start by catching the code prop
    if (props.code?.includes('public.tableau.com')) {
        return 'tableau';
    }
    if (
        props.code?.includes('youtube.com') ||
        props.url?.includes('youtube.com') ||
        props.code?.includes('youtu.be') ||
        props.url?.includes('youtu.be')
    ) {
        return 'youtube';
    }
    // Continue with simple embeds
    if (!props?.url) {
        return null;
    }
    if (props.url?.includes('vimeo.com/')) {
        return 'vimeo';
    }
    if (props.url?.includes('issuu.com/')) {
        return 'issuu';
    }

    if(props.url?.includes('typeform.com/')) {
        return 'typeform';
    }

    if (props.url?.includes('online.flippingbook.com/') ||
        props.url?.includes('publications.uroweb.org/')) {
        return 'flippingbook';
    }
    if (props.url?.includes('buzzsprout.com/')) {
        return 'buzzsprout';
    }

    return 'iframe';
});

const route = useRoute();
const progress = ref(0);

onMounted(() => {
    // Listener for clicks on typeform iframe
    if (props.url?.includes('typeform.com/')) {
        if (route?.params?.module) {
            const { trackEvent } = useCustomGtmEvent();
            const { moduleProgress, getModuleTitle } = useProgressStorage();
            progress.value = moduleProgress(route.params.module);

            const listener = window.addEventListener('blur', () => {
                if (document.activeElement === document.querySelector('iframe')) {
                    trackEvent({
                        event: 'start_typeform_module',
                        'module_progress': progress.value || null,
                        'module_name': getModuleTitle(route.params.module) || null,
                    });
                }
        
                window.removeEventListener('blur', listener);
            });
        }
    }
});
</script>

<style lang="less" src="./BaseEmbed.less" />
